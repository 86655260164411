function setLocalStorage(state = {}) {
  localStorage.setItem('localStorage', JSON.stringify(state));
}

export const state = () => ({
  athleteTableOptions: {
    itemsPerPage: 10,
  },
  athleteTableOptions: {
    itemsPerPage: 10,
  },
  coursesTableOptions: {
    itemsPerPage: 10,
  },
  bearer: null,
  loggedIn: false,
  me: null,
  staffMember: null,
  userBearer: null,
});

export const mutations = {
  SET_STATE(state, payload) {
    Object.assign(state, payload);
  },
  GOT_USER_BEARER(state, payload) {
    state.userBearer = payload;
    setLocalStorage(state);
  },
  GOT_BEARER(state, payload) {
    state.bearer = payload;
    setLocalStorage(state);
  },
  SET_STAFF_MEMBER(state, payload) {
    state.staffMember = payload;
    setLocalStorage(state);
  },
  SET_ME(state, payload) {
    state.me = Object.assign({}, payload);
    setLocalStorage(state);
  },
  USER_LOGGED_IN(state) {
    state.loggedIn = true;
    setLocalStorage(state);
  },
  USER_LOGGED_OUT(state) {
    state.loggedIn = false;
    state.me = null;
    state.bearer = null;
    setLocalStorage(state);
  },
  USER_UPDATED(state, payload) {
    state.me = payload;
    setLocalStorage(state);
  },
  USER_IMAGE_UPDATED(state, payload) {
    state.me.logo.path = payload.path;
    setLocalStorage(state);
  },

  SET_ATHLETE_TABLE_OPTIONS(state, options) {
    state.athleteTableOptions = {...options, page: 1};
    setLocalStorage(state);
  },
  SET_TRANSACTION_TABLE_OPTIONS(state, options) {
    state.transactionsTableOptions = {...options, page: 1};
    setLocalStorage(state);
  },
  SET_COURSE_TABLE_OPTIONS(state, options) {
    state.coursesTableOptions = {...options, page: 1};
    setLocalStorage(state);
  },
};
