
import ProfileDropdown from "../components/ProfileDropdown";
import SnackBarFeedback from "../components/Feedback/SnackBarFeedback";
import Subnav from "../components/Subnav";
import FullPageMessage from "../components/FullPageMessage.vue";
import DinkNavigationDrawer from "../components/Navigation/DinkNavigationDrawer.vue";
import MenuNode from "../components/Menu/MenuNode";
import { Settings } from "luxon";
import GlobalTimeFrameSelector from "../components/Forms/GlobalTimeFrameSelector.vue";
import FrillWidget from "../components/Widgets/FrillWidget.vue";
import flagsmith from 'flagsmith';

export default {
  name: "default",
  components: {
    ProfileDropdown,
    SnackBarFeedback,
    Subnav,
    FullPageMessage,
    DinkNavigationDrawer,
    MenuNode,
    GlobalTimeFrameSelector,
    FrillWidget,
  },
  async fetch() {
    const [timeFrames, user] = await Promise.all([
      this.$store.dispatch("configs/getTimeFrames"),
      this.$store.dispatch('staffMembers/getMe'),
      this.$store.dispatch('configs/getAvailableCurrencies'),
    ]);
    await flagsmith.identify(`org-${user.organization?.id}`);

    this.timeFrames = timeFrames ?? [];
    this.user = user ?? {};
  },
  data() {
    return {
      user: null,
      drawer: null,
      right: null,
      responsiveUsageImage: require("@/assets/static/images/go-desktop.svg"),
      timeFrames: null,
      isProduction: process.env.NODE_ENV === "production",
    };
  },
  computed: {
    isBoardMember() {
      return this.user?.roles?.includes("boardMember");
    },
    isManager() {
      return this.user?.roles?.includes("manager");
    },
    menuItems () {
      if(!this.user) return;
      const items = [
        {
          active: true,
          title: "Dashboard",
          icon: "mdi-finance",
          to: "/dashboard",
          roles: ["manager", "boardMember", "coach"],
          additionalInfo: null,
          children: [],
        },
        {
          active: false,
          title: "Staff",
          icon: "mdi-account-group-outline",
          to: "/staff",
          roles: ["manager", "boardMember"],
          additionalInfo: null,
          children: [],
        },
        {
          active: false,
          title: "Scuola",
          icon: "mdi-volleyball",
          to: "/my-organization",
          roles: ["manager", "boardMember", "coach"],
          children: [
            {
              title: "Iscrizioni",
              to: "/my-organization/registration",
              roles: ["manager", "boardMember"],
            },
            {
              title: "Academy",
              roles: ["manager", "boardMember", "coach"],
              children: [
                {
                  title: "Allenamenti",
                  to: "/my-organization/course-sessions",
                  additionalInfo: null,
                  roles: ["manager", "boardMember", "coach"],
                },
                {
                  title: "Corsi",
                  to: "/my-organization/courses",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
                // {
                //   title: "Planner",
                //   to: "/my-organization/courses-planner",
                //   additionalInfo: "Beta",
                // },
              ],
            },
            {
              title: "Segreteria",
              to: "/my-organization/memberships",
              additionalInfo: null,
              roles: ["manager", "boardMember"],
              children: [
                {
                  title: "Atleti",
                  to: "/my-organization/memberships",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
                {
                  title: "Transazioni",
                  to: "/my-organization/transactions",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
                {
                  title: "Livelli",
                  to: "/my-organization/levels",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
                {
                  title: "Gruppi",
                  to: "/my-organization/groups",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
              ],
            },
            {
              title: "Listino",
              roles: ["manager", "boardMember"],
              children: [
                {
                  title: "Pacchetti",
                  to: "/my-organization/subscription-templates",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
                {
                  title: "Promozioni",
                  to: "/my-organization/promotions",
                  additionalInfo: null,
                  roles: ["manager", "boardMember"],
                },
              ],
            },
            {
              title: "Sedi",
              to: "/my-organization/sites",
              additionalInfo: null,
              roles: ["manager", "boardMember"],
            },

            {
              title: "Statistiche",
              to: "/my-organization/analytics",
              additionalInfo: null,
              roles: ["manager", "boardMember"],
            },
          ],
        },
        {
          active: false,
          title: "Eventi",
          icon: "mdi-tournament",
          to: "/events",
          additionalInfo: null,
          roles: ["manager", "boardMember"],
          children: [
            {
              title: "I miei eventi",
              to: "/events/list?show=mine",
              additionalInfo: null,
              roles: ["manager", "boardMember"],
            },
            {
              title: "Campionati",
              to: "/championships",
              additionalInfo: null,
              roles: ["manager", "boardMember"],
            },
            {
              active: false,
              title: "Simulatori",
              to: "/simulators",
              roles: ["manager", "boardMember"],
              additionalInfo: null,
              children: [
                {
                  title: "Simulatore Torneo",
                  to: "/simulators/tournament",
                  roles: ["manager", "boardMember"],
                  additionalInfo: null,
                },
                {
                  title: "Simulatore economico",
                  to: "/simulators/revenue",
                  roles: ["manager", "boardMember"],
                  additionalInfo: null,
                },
              ],
            },
            {
              title: "Statistiche",
              to: "/events/analytics",
              roles: ["manager", "boardMember"],
              additionalInfo: null,
            },
          ],
        },

        {
          active: false,
          title: "Strutture",
          icon: "mdi-pin-outline",
          to: "/locations",
          roles: ["manager", "boardMember"],
          additionalInfo: null,
        },

        {
          active: false,
          title: "Configurazioni",
          icon: "mdi-tune-vertical",
          to: "/settings",
          roles: ["manager", "boardMember"],
          additionalInfo: null,
          children: [
            {
              title: "Blocchi temporali",
              to: "/settings/time-frames",
              roles: ["manager", "boardMember"],
              additionalInfo: null,
            },
          ],
        },
        {
          active: false,
          title: "Segui gli sviluppi",
          icon: "mdi-arm-flex-outline",
          to: "/feedback",
          roles: ["manager", "boardMember", "coach"],
          additionalInfo: null,
        },
        {
          active: false,
          title: "Documentazione",
          icon: "mdi-file-document-outline",
          to: "/documentation",
          roles: ["manager", "boardMember", "coach"],
          additionalInfo: null,
          children: [
            {
              title: "Dashboard di admin",
              to: "/documentation/dashboard",
              additionalInfo: null,
              roles: ["manager", "boardMember", "coach"],
            },
            {
              title: "App atleti",
              to: "/documentation/mobile-app",
              additionalInfo: null,
              roles: ["manager", "boardMember", "coach"],
            },
          ]
        },
        {
          active: false,
          title: "Note legali",
          icon: "mdi-information",
          to: "/information",
          roles: ["manager", "boardMember", "coach"],
          additionalInfo: this.user.organization.dinkContractUrl === '' ? '1' : null,
        },
      ]
      return this.filterMenuItems(items, this.user);
    }
  },
  methods: {
    filterMenuItems(menuItems, user) {
      const filtered = menuItems.filter((item) => {
        if (user.roles.some((el) => item.roles.includes(el))) {
          if (item.children?.length > 0) {
            item.children = this.filterMenuItems(item.children, user);
          }
          return true;
        }
        return false;
      });
      return filtered;
    },
    initializeFeaturebase() {
      !(function (e, t) {
        const a = "featurebase-sdk";
        function n() {
          if (!t.getElementById(a)) {
            var e = t.createElement("script");
            (e.id = a),
              (e.src = "https://do.featurebase.app/js/sdk.js"),
              t
                .getElementsByTagName("script")[0]
                .parentNode.insertBefore(
                  e,
                  t.getElementsByTagName("script")[0]
                );
          }
        }
        "function" != typeof e.Featurebase &&
          (e.Featurebase = function () {
            (e.Featurebase.q = e.Featurebase.q || []).push(arguments);
          }),
          "complete" === t.readyState || "interactive" === t.readyState
            ? n()
            : t.addEventListener("DOMContentLoaded", n);
      })(window, document);

      Featurebase("initialize_changelog_widget", {
        organization: "Dink", // Replace this with your featurebase organization name
        placement: "right", // Choose between right, left, top, bottom placement
        theme: "light", // Choose between dark or light theme
      });
    },
  },
  async created() {
    const locale = localStorage.getItem('locale') || (navigator.language.split('-')[0]) || 'it';
    this.$moment.locale(locale);
    Settings.defaultLocale = locale;
    this.$i18n.setLocale(locale);
    
    if (this.$store.state.localStorage.bearer) {
      if (!this.$store.state.user.loggedIn) {
        this.$store.commit("localStorage/USER_LOGGED_IN");
      }
      this.$store.commit("configs/SET_CURRENT_TIME_FRAME", {
        startAt: null,
        endAt: null,
        timeFrameName: null,
      });
      try {
        this.drawer = false;
        // user.roles = ['boardMember', 'coach'];
        // Use the following line for testing purposes
        // this.user.roles = ["coach"];
        // this.user.roles = [...new Set(this.user.roles)];
      } catch (e) {
        console.log(e);
      } finally {
        this.drawer = true;
      }
    }
  },
  mounted() {
    this.initializeFeaturebase();
  },
};
