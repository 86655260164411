export const state = () => ({
    locations: [],
    location: {},
});

export const getters = {
    locationHtmlDescription: state => {
        return state.location?.description?.replace(/\n/g, "<br />");
    },
    locationAccommodationHtmlDescription: state => {
        return state.location?.accommodation?.replace(/\n/g, "<br />");
    },
    locationParkingHtmlDescription: state => {
        return state.location?.parking?.replace(/\n/g, "<br />");
    },
    locationFoodHtmlDescription: state => {
        return state.location?.food?.replace(/\n/g, "<br />");
    },
}

export const mutations = {
    SET_LOCATIONS(state, payload) {
        state.locations = payload;
    },
    SET_LOCATION(state, payload) {
        state.location = payload;
    },
};

export const actions = {
    async createLocation({ commit, state, rootState }, data) {
        try {
            await this.$axios.post('/locations', data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo salvato la struttura con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return true;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the location", err)
            return false;
        }
    },
    async fetchLocations({ commit, rootState }) {
        try {
            const res = await this.$axios.get('/locations');
            return res.data;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nel recuperare le tue strutture",
                show: true,
                timeout: 3000,
            }, { root: true });
            console.warn("Something went wrong with locations", err)
        }
    },
    async fetchLocationById({ commit, rootState }, id) {
        try {
            const res = await this.$axios.get('/locations/' + id);
            return res.data
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto nel recuperare questa struttura",
                show: true,
                timeout: 3000,
            }, { root: true });
            console.warn("Something went wrong with the location", err)
        }

        commit('SET_LOADING', false, { root: true });
    },
    async patchLocation ({ commit, state, rootState }, data) {
        try {
            await this.$axios.patch('/locations/' + data.id, data);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Abbiamo salvato le nuove modifiche con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return true;
        } catch (err) {
            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: "Qualcosa è andato storto",
                show: true,
                timeout: 3000
            }, { root: true });
            console.log("Something went wrong with the location patch", err)
            return false;
        }
    },
    async deleteLocation({ commit, rootState }, id) {
        try {
            await this.$axios.delete('/locations/' + id);

            commit("user/SHOW_MESSAGE", {
                color: "success",
                message: "Struttura cancellata con successo",
                show: true,
                timeout: 3000,
            }, { root: true });
            return true;
        } catch (error) {
            let errorMessage = "";
            switch (error.response.status) {
                case 500:
                    errorMessage = "Problema sconosciuto del server, contatta un amministratore"
                    break;
                case 404:
                    errorMessage = "Non abbiamo trovato la risorsa da eliminare, contatta un amministratore"
                    break;
                case 400:
                    errorMessage = "Non puoi eliminare questa location perché è associata a un evento"
            }

            commit("user/SHOW_MESSAGE", {
                color: "error",
                message: errorMessage,
                show: true,
                timeout: 4000
            }, {root: true});
            console.warn("There was an errror during PASSWORD RECOVER", error);

            return false;
        }
    }
};
