const weekdaysMap = {
  mon: "monday",
  tue: "tuesday",
  wed: "wednesday",
  thu: "thursday",
  fri: "friday",
  sat: "saturday",
  sun: "sunday",
};

export default ({ app }, inject) => {
  // Deep copy an object
  inject("copy", obj => structuredClone(obj));
  inject("cp", obj => structuredClone(obj));

  // Return the full path for the given image
  inject("image", function(fileData = {}, type) {
    return `${fileData.baseURL}${fileData[type]}`;
  });
  
  inject("weekday", function(shortDay = '') {
    return weekdaysMap[shortDay.toLowerCase()]
  });
};
