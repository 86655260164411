
export default {
  name: "BaseModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: 'auto'
    },
    dialogClass: {
      type: String,
      default: ''
    },
    modalHeadingClass: {
      type: String,
      default: 'auto'
    },
    contentSlotClass: {
      type: String,
      default: ''
    },
    noGutters: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    hideSubtitle: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    actionsFixed: Boolean,
    showFooterDivider: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      required: false
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$emit("input", false);
    },
    outsideClick () {
      if (this.persistent) return;
      if (!this.persistent) {
        this.closeModal();
      }
    }
  },
};
