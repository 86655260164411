import { render, staticRenderFns } from "./blank.vue?vue&type=template&id=2ebef300"
import script from "./blank.vue?vue&type=script&lang=js"
export * from "./blank.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__lodash_6u3ubhqoq7yqxjco33hzwwh4mq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports